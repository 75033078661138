<template>
<div class="my-0">
  <div v-if="reloading" class="text-center">
    <div class="text-center" style="background: white; height: 50vh; overflow-y: auto;  overflow-x: auto;">
      <i class="fa fa-spinner fa-pulse fa-lg fa-fw" style="font-size: 6em; margin-top: 100px;"></i>
    </div>
  </div>
  <b-container fluid v-if="!reloading">
    <b-row class="mb-0">
      <b-col lg="2" class="py-3 px-0" style="background: white;">
        <b-button class="mx-4" @click="download">Download policies</b-button>
      </b-col>
      <b-col lg="10" class="py-3" style="background: white;">
        <b-container fluid>
          <b-row class="mb-4">
            <b-col>
              <b-form>
                <b-form-select v-model="countrySelected1" :options="countryOptions1" size="lg" style="font-weight: 900"></b-form-select>
              </b-form>
            </b-col>
            <b-col>
              <b-form>
                <b-form-select v-model="countrySelected2" :options="countryOptions2" size="lg" style="font-weight: 900"></b-form-select>
              </b-form>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col lg="2" class="py-3 px-0" style="background: white; height: 50vh; overflow-y: auto;  overflow-x: auto;">
        <div>
          <div class="accordion" role="tablist">
            <div v-for="(row, index) in initiativesByAreaAll" :key="'topicHeading-' + index">
              <div header-tag="header" role="tab">
                <div class="px-3 py-3" v-b-toggle="'accordion-' + index"><strong>{{row.area}}</strong></div>
              </div>
              <b-collapse :id="'accordion-' + index" :visible="index === 0" accordion="my-accordion" role="tabpanel">
                <div>
                  <div v-for="(policy, index2) in row.policies" :key="'initiative-' + index2">
                    <div class="px-3 py-3" style="cursor: pointer;" :id="'policy' + policy.policy.id"><a :href="'#' + camelCase(policy.policy.name)">{{ policy.policy.name }}</a></div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="10" class="py-3" style="background: white; height: 50vh; overflow-y: auto;  overflow-x: auto;">
        <div>
          <div v-for="policy in policiesComparison" :key="'policy'+ policy.policy.id">
            <policy-comparison :policy="policy" />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import PolicyComparison from '@/components/PolicyComparison'

// delay function in seconds
function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  name: 'CountryPolicies',
  components: {
    PolicyComparison
  },
  computed: {
    countryOptions1: function () {
      const all = this.$store.state.initiativesPolicies
      let results = all.map(x => x.country)
      results = _.uniq(results)
      _.pull(results, this.countrySelected2)
      results.sort()
      return results
    },
    countryOptions2: function () {
      const all = this.$store.state.initiativesPolicies
      let results = all.map(x => x.country)
      results = _.uniq(results)
      _.pull(results, this.countrySelected1)
      results.sort()
      return results
    },
    initiatives: function () {
      const country1 = this.countrySelected1
      const country2 = this.countrySelected2
      const results = []
      const all = this.$store.state.initiativesPolicies
      all.forEach(x => {
        if (x.country === country1 || x.country === country2) {
          results.push(x)
        }
      })
      return results
    },
    initiativesByAreaAll: function () {
      const c1 = this.countrySelected1
      const c2 = this.countrySelected2
      const all = []
      const allInitiatives = this.$store.state.initiativesPolicies
      allInitiatives.forEach(x => {
        if (x.country === c1 || x.country === c2) {
          all.push(x)
        }
      })
      const results = []
      const policies = this.policies
      this.areas.forEach(area => {
        let row = {
          area: area.name,
          policies: []
        }
        policies.forEach(policy => {
          const country1 = all.find(x => {
            let t = false
            const areas = x.impactpolicy.taxonomy.find(y => y.id === area.id)
            if (areas) {
              t = true
            }
            return x.impactpolicy_id === policy.id && x.country === c1 && t
          })
          const country2 = all.find(x => {
            let t = false
            const areas = x.impactpolicy.taxonomy.find(y => y.id === area.id)
            if (areas) {
              t = true
            }
            return x.impactpolicy_id === policy.id && x.country === c2 && t
          })
          const rowInitiatives = {
            policy: policy,
            initiatives: [country1, country2]
          }
          if (country1 || country2) {
            row.policies.push(rowInitiatives)
          }
        })
        results.push(row)
      })
      results.forEach(row => {
        const policies = _.orderBy(row.policies, ['policy.overview', 'name'], ['desc', 'asc'])
        row.policies = policies
      })

      return results
    },
    policiesComparison: function () {
      const c1 = this.countrySelected1
      const c2 = this.countrySelected2
      const all = []
      const allInitiatives = this.$store.state.initiativesPolicies
      allInitiatives.forEach(x => {
        if (x.country === c1 || x.country === c2) {
          all.push(x)
        }
      })
      const results = []
      const policies = this.policies
      policies.forEach(policy => {
        const country1 = all.find(x => {
          return x.impactpolicy_id === policy.id && x.country === c1
        })
        const country2 = all.find(x => {
          return x.impactpolicy_id === policy.id && x.country === c2
        })
        const rowInitiatives = {
          policy: policy,
          initiatives: [country1, country2]
        }
        if (country1 || country2) {
          results.push(rowInitiatives)
        }
      })
      return results
    },
    policies: function () {
      const all = this.initiatives
      let results = []
      all.forEach(initiative => {
        if (initiative.impactpolicy_id) {
          results.push(initiative.impactpolicy)
        }
      })
      results = _.uniqBy(results, 'id')
      results = _.sortBy(results, ['name'])
      return results
    },
    areas: function () {
      const all = this.initiatives
      let results = []
      all.forEach(i => {
        if (i.impactpolicy.taxonomy.length > 0) {
          i.impactpolicy.taxonomy.forEach(taxonomy => {
            results.push(taxonomy)
          })
        }
      })
      results = _.uniqBy(results, 'id')
      results.sort()
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "Climate Policy and Regulatory Dashboard: " + this.$route.params.country1 + ' vs ' + this.$route.params.country2
    this.$stat.log({ page: 'impactpolicies list', action: 'open impactpolicies list' })
    if (this.$route.params.country1) {
      this.countrySelected1 = this.$route.params.country1
      window.localStorage.setItem('frmCloudCountrySelected1', this.countrySelected1)
    } else if (window.localStorage.getItem('frmCloudCountrySelected1')) {
      this.countrySelected1 = window.localStorage.getItem('frmCloudCountrySelected1')
    } else {
      this.countrySelected1 = this.countryOptions1[0]
    }
    if (this.$route.params.country2) {
      this.countrySelected2 = this.$route.params.country2
      window.localStorage.setItem('frmCloudCountrySelected2', this.countrySelected2)
    } else if (window.localStorage.getItem('frmCloudCountrySelected2')) {
      this.countrySelected2 = window.localStorage.getItem('frmCloudCountrySelected2')
    } else {
      this.countrySelected2 = this.countryOptions2[0]
    }
    if (this.countrySelected1 === null || this.countrySelected1 === 'null') {
      this.countrySelected1 = this.countryOptions1[0]
    }
    if (this.countrySelected2 === null || this.countrySelected2 === 'null') {
      this.countrySelected2 = this.countryOptions2[0]
    }
    this.reloading = false
  },
  data () {
    const data = {
      camelCase: _.camelCase,
      reloading: true,
      countrySelected1: null,
      countrySelected2: null,
      staticDataProperties: [
        { name: 'Areas', property: 'area', graph: 'area', tree: true },
        { name: 'Countries', property: 'country', direct: true },
        { name: 'Policies', property: 'impactpolicy', graph: 'impactpolicy', tree: true },
        { name: 'Sectors', property: 'sectors', graph: 'sectors', sectors: true },
        { name: 'Status', property: 'impactstatus', graph: 'impactstatus', tree: true }
      ]
    }
    return data
  },
  methods: {
    download: function () {
      const filterSelected = this.$store.state.filterSelected
      filterSelected.cprd = {}
      this.staticDataProperties.forEach(row => {
        filterSelected.cprd[row.property] = []
      })
      filterSelected.cprd.country = [this.countrySelected1, this.countrySelected2]
      this.$store.commit('setFilterSelected', filterSelected)
      this.$router.push({ name: 'ImpactPoliciesDownload' })
    },
    load: async function () {
    }
  },
  watch: {
    '$route.params.country1': function () {
      this.countrySelected1 = this.$route.params.country1
      this.countrySelected2 = this.$route.params.country2
      document.title = "Climate Policy Dashboard: " + this.$route.params.country1 + ' vs ' + this.$route.params.country2
      this.reloading = false
    },
    '$route.params.country2': function () {
      this.countrySelected1 = this.$route.params.country1
      this.countrySelected2 = this.$route.params.country2
      document.title = "Climate Policy Dashboard: " + this.$route.params.country1 + ' vs ' + this.$route.params.country2
      this.reloading = false
    },
    'countrySelected1': async function () {
      window.localStorage.setItem('frmCloudCountrySelected1', this.countrySelected1)
      if (this.countrySelected1 !== this.$route.params.country1) {
        this.reloading = true
        await delay(500)
        this.$router.push({ name: 'ImpactPoliciesCountryComparison', params: { country1: this.countrySelected1, country2: this.countrySelected2 } })
      }
    },
    'countrySelected2': async function () {
      window.localStorage.setItem('frmCloudCountrySelected2', this.countrySelected2)
      if (this.countrySelected2 !== this.$route.params.country2) {
        this.reloading = true
        await delay(500)
        this.$router.push({ name: 'ImpactPoliciesCountryComparison', params: { country1: this.countrySelected1, country2: this.countrySelected2 } })
      }
    }
  }
}
</script>

<style>
</style>
